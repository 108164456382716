import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';
import { FindSales as FindSalesIcon, BuyOnline as BuyOnlineIcon } from '../../../images/svg-icons';


const Page = ({ data }) => (
  <>
    <SEO
      title="Dépensez Neosurf – un moyen anonyme de payer en ligne"
      description="Les bons Neosurf sont acceptés par des milliers de commerçants dans le monde entier."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf/spend-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf/spend-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Dépensez Neosurf</h1>
            <p className="textColorDark">Utilisez votre bon Neosurf – Passez aux cryptos !</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textAlignCenter"><FindSalesIcon width="100" /></p>
      <p className="textColorDark textAlignCenter"><span className="sectionMarker">1</span> Find your nest casino platform</p>
      <div className="grid c-2">
        <div className="textAlignCenter">
          <p><span className="sectionMarker">2</span></p>
          <p className="textColorDark"><strong>Select Neosurf</strong> during checkout</p>
        </div>
        <div className="textAlignCenter">
          <p><span className="sectionMarker">3</span></p>
          <p className="textColorDark"><strong>Enter your pincode</strong> to pay</p>
        </div>
      </div>
      <p className="textAlignCenter"><BuyOnlineIcon width="100" /></p>
      <p className="textColorDark textAlignCenter">Simply deposit with crypto to get started</p>
      <p className="textAlignCenter"><a className="button">Make crypto deposit</a></p>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Vous avez déjà un bon Neosurf et êtes prêt à l’utiliser ? Les bons Neosurf sont acceptés par des milliers de commerçants dans le monde entier et offrent un moyen sûr et anonyme de payer en ligne. Que vous rechargiez un compte de jeu ou effectuiez une autre transaction en ligne, utiliser Neosurf est aussi simple que de saisir le code unique fourni avec le bon lors du paiement.</p>
      <p className="textColorDark">Les bons Neosurf sont particulièrement populaires auprès des joueurs et des amateurs de divertissement, c’est pourquoi de nombreuses plateformes de jeux en ligne acceptent Neosurf. Ils permettent aux utilisateurs de financer leurs comptes rapidement et facilement sans avoir besoin d’une carte de crédit ou d’un compte bancaire. Il vous suffit d’accéder à la section de paiement, de sélectionner Neosurf comme méthode de paiement et de saisir votre code. Les fonds sont immédiatement appliqués, ce qui vous laisse plus de temps pour profiter de vos jeux préférés.</p>
      <p className="textColorDark">Si vous recherchez encore plus de flexibilité, explorez Bitcoin. C’est une monnaie numérique acceptée dans le monde entier et une excellente alternative pour les utilisateurs qui souhaitent plus de polyvalence dans leurs paiements en ligne. Cela est particulièrement utile sur des plateformes comme LuckyStar.io, où vous pouvez déposer des fonds de manière instantanée et sécurisée en utilisant Bitcoin. Voici comment utiliser Bitcoin si vous envisagez cette option:</p>
      <ol>
        <li>
          <p className="textColorDark">Configurer un portefeuille Bitcoin - Votre portefeuille est l’endroit où vous stockerez votre cryptomonnaie, y compris Bitcoin. Vous pouvez choisir parmi différentes options : plateformes en ligne, applications ou même portefeuilles matériels pour une sécurité maximale.</p>
        </li>
        <li>
          <p className="textColorDark">Acheter du Bitcoin - Achetez du Bitcoin sur des plateformes d’échange de confiance avec votre méthode de paiement préférée.</p>
        </li>
        <li>
          <p className="textColorDark">Utiliser Bitcoin - Utilisez Bitcoin pour effectuer des paiements sur des plateformes acceptant les cryptomonnaies, comme les casinos en ligne, les sites de shopping et bien plus encore.</p>
        </li>
      </ol>
      <p className="textColorDark">Bitcoin est également une solution idéale si vous avez des difficultés à trouver des bons Neosurf dans votre région ou si vous recherchez un mode de paiement encore plus universellement accepté. Avec Bitcoin, vous pouvez facilement contourner les limitations régionales et profiter de la commodité des transactions instantanées et sécurisées, où que vous soyez dans le monde.</p>
      <p className="textColorDark">Pour ceux qui possèdent déjà un bon Neosurf, cela reste une méthode de paiement fantastique pour des plateformes spécifiques. Cependant, si vous souhaitez explorer une solution numérique, Bitcoin est le meilleur choix. Il offre tous les avantages en matière de confidentialité et de sécurité auxquels vous êtes habitué avec les bons Neosurf, tout en offrant des fonctionnalités supplémentaires telles que l’accessibilité mondiale.</p>
      <p className="textColorDark">Que vous dépensiez votre bon Neosurf ou que vous envisagiez de passer à Bitcoin, de nombreuses options s’offrent à vous. Découvrez dès aujourd’hui les avantages des deux méthodes et voyez à quel point les paiements en ligne peuvent être simples et sécurisés. Et si vous souhaitez en savoir plus sur les paiements Bitcoin, visitez LuckyStar.io.</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "buy_neosurf/hero.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    find_icon: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "buy_neosurf/find_sales_outlets.svg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
